// eslint-disable-next-line no-unused-vars
import {evite} from 'evite';
import Appsignal from '@appsignal/javascript';
import {plugin} from '@appsignal/plugin-window-events';

const pathDecorator = (span) => {
  span.setTags({path: window.location.pathname});
};

export const appsignal = new Appsignal({
  key: window.app_signal_api_key,
  revision: process.env.VERSION,
});
appsignal.use(plugin()); // should log all uncaught errors in AppSignal
appsignal.addDecorator(pathDecorator); // Pass page path to AppSignal

document.body.addEventListener('pointermove', () => {
  document.body.classList.add('using-mouse');
});

document.body.addEventListener('keydown', () => {
  document.body.classList.remove('using-mouse');
});
