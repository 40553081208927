const evite = (window.evite = window.evite || {});

if (evite.detects) {
  throw new Error(
    'Unexpected copy of evite.detects module exported, you might have used an unusual import instead of the "evite" alias.'
  );
}

const useragent = require('express-useragent');

// exporting so user can inspect things in evite.detects.userAgentData
export const userAgentData = new useragent.UserAgent().parse(navigator.userAgent);

/*
userAgentData will have values like this:
  browser: "Chrome"
  geoIp: {}
  isAlamoFire: false
  isAmaya: false
  isAndroid: false
  isAndroidTablet: false
  isAuthoritative: true
  isBada: false
  isBlackberry: false
  isBot: false
  isCaptive: false
  isChrome: true
  isChromeOS: false
  isCurl: false
  isDesktop: true
  isEdge: false
  isElectron: false
  isEpiphany: false
  isFacebook: false
  isFirefox: false
  isFlock: false
  isIE: false
  isIECompatibilityMode: false
  isKindleFire: false
  isKonqueror: false
  isLinux: false
  isLinux64: false
  isMac: true
  isMobile: false
  isOmniWeb: false
  isOpera: false
  isPhantomJS: false
  isRaspberry: false
  isSafari: false
  isSamsung: false
  isSeaMonkey: false
  isSilk: false
  isSmartTV: false
  isTablet: false
  isUC: false
  isWebkit: false
  isWinJs: false
  isWindows: false
  isYaBrowser: false
  isiPad: false
  isiPhone: false
  isiPod: false
  os: "macOS Mojave"
  platform: "Apple Mac"
  silkAccelerated: false
  source: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.129 Safari/537.36"
  version: "81.0.4044.129"
*/

// platforms
export const isIPad = userAgentData.isiPad;
export const isIPhone = userAgentData.isiPhone;
export const {isAndroid} = userAgentData;

export const isIOS = isIPad || isIPhone;

export const isIOSWebview = () => window?.webkit?.messageHandlers?.fabricCreateOnNav;

/* note: currently we're using our own determination of mobile vs.
   desktop... (see below)
export const isMobile = userAgentData.isMobile;
export const isDesktop = userAgentData.isDesktop;
*/

export const isMobile = Boolean(window.is_mobile_site || window.is_mobile || isIPhone || isAndroid);
export const isDesktop = !isMobile;

export const {isMac} = userAgentData;
export const {isWindows} = userAgentData;

export const {os} = userAgentData; // something like "macOS Mojave"
export const {platform} = userAgentData; // something like "Apple Mac"

// browsers
export const {isIE} = userAgentData;
export const {isEdge} = userAgentData;
export const {isChrome} = userAgentData;
export const {isFirefox} = userAgentData;
export const {isSafari} = userAgentData;

export const {browser} = userAgentData; // something like "Chrome"
export const browserVersion = userAgentData.version; // something like "81.0.4044.129"

export const getBrowserInfo = () =>
  `This browser is ${browser} version ${browserVersion} running on ${platform}.`;

export const printBrowserInfo = () => {
  console.log(getBrowserInfo());
};
