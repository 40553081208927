const evite = (window.evite = window.evite || {});
if (evite.Emitter) {
  throw new Error(
    'Unexpected copy of evite.Emitter module exported, you might have used an unusual import instead of the "evite" alias.'
  );
}

export class Emitter {
  constructor() {
    const delegate = document.createDocumentFragment();
    this.addEventListener = delegate.addEventListener.bind(delegate);
    this.removeEventListener = delegate.removeEventListener.bind(delegate);
    this.dispatchEvent = delegate.dispatchEvent.bind(delegate);
  }
}

// PolyFill for new Event in IE
(function () {
  if (typeof window.CustomEvent === 'function') return false; // If not IE

  function CustomEvent(event, params) {
    params = params || {bubbles: false, cancelable: false, detail: undefined};
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();
