import Raven from '~js-lib/raven';
import * as _store from './evite_store';
import {Emitter as _Emitter} from './Emitter';
import * as _debugging from './debugging';
import * as _detects from './detects';
import * as _features from './features';
import * as _promises from './promises';
import * as _dom from './dom';
import {history as _history} from './history';
import * as _utils from './utils';
import {urls as _urls} from './urls';
import {cookie as _cookie} from './cookie';
import * as _experiments from './experiments';

export const Emitter = _Emitter;
export const debugging = _debugging;
export const detects = _detects;
export const features = _features;
export const dom = _dom;
export const promises = _promises;
export const utils = _utils;
export const urls = _urls;
export const cookie = _cookie;

const root = typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : {};

export const evite = (window.evite = window.evite || {});
evite.global = root;

global.require =
  global.require ||
  function (name) {
    return root[name];
  };

if (evite.DEFINED) {
  throw new Error(
    'Unexpected copy of evite module exported, you might have used an unusual import instead of the "evite" alias.'
  );
}
evite.DEFINED = true;

Object.assign(exports, debugging, dom, utils, promises);

evite.history = _history;
//
Object.assign(evite, exports);
if (evite.experiments) {
  Object.assign(evite.experiments, _experiments);
}
root.Raven = Raven;

evite.resolve('evite', evite);
