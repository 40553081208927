import trim from 'lodash/trim';
import urls from './urls';
import {getHostName} from '~/common/evite/utils';
import {isIOSWebview} from './detects';

export const getExperimentCookie = (experimentName) => evite.cookie(`exp-${experimentName}`);

const reportedBTB = {};
export const reportBackendTestBucket = (experimentName, variationName, justOnce) => {
  if (justOnce) {
    if (reportedBTB[experimentName] === variationName) return;
    reportedBTB[experimentName] = variationName;
  }
  if (window.dataLayer)
    window.dataLayer.push({
      event: 'backend_test_bucket',
      first_assignment: true,
      optimizely_experiment: experimentName,
      optimizely_variation: variationName,
    });
};

export const setExperimentCookie = (experimentName, variationName) => {
  if (getExperimentCookie(experimentName) === variationName) {
    return;
  }
  evite.cookie(`exp-${experimentName}`, variationName, {
    domain: getHostName(),
    expires: 30,
    path: '/',
  });
  evite.when?.('boomr').then(() => {
    window.BOOMR.appendVar('evt_abtest', `${experimentName}-${variationName}`);
    window.BOOMR.hit(`joined_abtest:${experimentName}-${variationName}`);
  });

  reportBackendTestBucket(experimentName, variationName);
};

/**
 * Check if a user is in a given experiment variation
 *
 * @example evite.experiments.inVariation('test1', 'variation1', false);
 * @desc Get the value of a cookie.
 *
 * @param experimentName: String name The name of the experiment (ab test).
 * @param variationName: String name The name of the experiment variation.
 * @param defaultValue: Default return for inVariation. If true, will
 * default to the user being in the specified variation. If false, will
 * default to the "original" variation.
 * @param readOnly: inVariation call with readOnly=true will not bucket the user
 * @return Boolean - is the user in the experiment variation?
 *
 */
const chooseVariation = (variations) => {
  const randomSpin = Math.random() * 100 * 100;
  let cumulativeWeights = 0;

  for (const [name, weight] of Object.entries(variations)) {
    cumulativeWeights += weight;
    if (randomSpin <= cumulativeWeights) {
      return name;
    }
  }
  throw Error('bad experiment weights - must sum to 10000');
};

const chooseParticipation = (experiment) => {
  if (!experiment.percentage_included || experiment.percentage_included === 100 * 100) {
    return true;
  }
  const randomSpin = Math.random() * 100 * 100;
  return randomSpin < experiment.percentage_included;
};

export const bucketUser = (experimentName, experimentData, readOnly) => {
  if (
    !experimentData ||
    evite.experiments.notParticipating.includes(experimentName) ||
    isIOSWebview()
  )
    return null;

  if (evite.experiments.userExperiments[experimentName]) {
    // we already chose a variation
    return evite.experiments.userExperiments[experimentName];
  }

  let variationName = getExperimentCookie(experimentName);
  if (variationName) variationName = trim(variationName, '"');

  let variation;
  if (variationName === '.') {
    variation = variationName;
    evite.experiments.notParticipating.push(experimentName);
    return variation;
  }
  if (variationName) {
    for (const name of Object.keys(experimentData.variations)) {
      if (name === variationName) {
        variation = name;
        evite.experiments.userExperiments[experimentName] = variation;
        return variation;
      }
    }
  }
  if (readOnly && !variation) return undefined;
  if (chooseParticipation(experimentData)) {
    if (window.Cypress)
      if (Object.values(experimentData.variations).filter((v) => v > 0).length > 1) {
        // If there is more than one enabled variation in a non-forced experiment we throw
        // an error during testing, so we get repeatable errors instead of random errors.
        throw new Error(
          `Choosing random experiment variations during e2e testing leads to sadness. (${experimentName})`
        );
      }
    variation = chooseVariation(experimentData.variations);
    evite.experiments.userExperiments[experimentName] = variation;
  } else {
    variation = '.';
    evite.experiments.notParticipating.push(experimentName);
  }
  setExperimentCookie(experimentName, variation);

  return variation;
};

const getActiveExperiments = () => {
  if (!window.getMockedExperiments) return evite.experiments.activeExperiments;
  return {...evite.experiments.activeExperiments, ...window.getMockedExperiments()};
};

export const getOrSetVariation = (experimentName, defaultValue = null, readOnly = false) => {
  const experimentData = getActiveExperiments()[experimentName];
  if (!experimentData || !['RUNNING', 'FORCED'].includes(experimentData.status)) {
    return defaultValue;
  }
  // if there is a forced experiment-variation, bypass the rest of the logic
  if (experimentData.status === 'FORCED' && experimentData.forced_variation) {
    return experimentData.forced_variation;
  }
  return bucketUser(experimentName, experimentData, readOnly);
};

export const inVariation = (
  experimentName,
  variationName = null,
  defaultValue = false,
  readOnly = false
) => {
  const variation = getOrSetVariation(experimentName, null, readOnly);
  if (variation === null) return defaultValue;
  if (!variation || variation === '.') return false;
  return variation === variationName;
};

export const loadExperimentsData = () => {
  try {
    fetch(urls.get('ajax_experiments'), {
      Accept: 'application/json',
    }).then((response) => {
      if (!response.ok) return null;
      return response.json();
    });
  } catch (e) {
    evite.error('Failed to get experiment data', e);
    return null;
  }
  return null;
};

export const setupExperiments = (function () {
  window.evite = window.evite || {};
  evite.experiments = window.evite.experiments || {};
  evite.experiments.notParticipating = [];
  evite.experiments.activeExperiments = evite.experiments.activeExperiments || {};
  evite.experiments.userExperiments = {};
})();
