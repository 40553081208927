import * as mobx from 'node-modules-mobx';

const evite = (window.evite = window.evite || {});
if (evite.store) {
  throw new Error(
    'Unexpected copy of evite.store module exported, you might have used an unusual import instead of the "evite" alias.'
  );
}
// this sets up the external for mobx site-wide
if (evite.exports) evite.exports.mobx = mobx;
