/** @fileOverview
 * Allows client side feature switches down to the device browser.
 * Notes:
 *   - Feature switches not defined in cstool are enabled by default
 *   - Android and Iphone are implicitly mobile
 *   - Tablet is considered desktop
 *   - IMPORTANT: In order for a feature switch to appear in the frontend, its name must end with a '|'
 *        - without the pipe, the feature will not be passed from the backend to the frontend
 *
 * @code
 *  `evite.features.isEnabled('mentions');`
 *
 * CSTool: (Most specific key that matches the current environment wins)
 *  (lowest specificity)
 *    mentions - All Mentions
 *
 *    mentions|desktop - Desktop
 *    mentions|desktop|edge - Desktop Edge only
 *    mentions|desktop|ie - Desktop IE only
 *
 *    mentions|mobile - All mobile Browsers
 *    mentions|mobile|firefox - Firefox on mobile
 *
 *    mentions|mobile|android - Only Android users
 *    mentions|mobile|iphone - Only Iphone users
 *
 *  (highest specificity)
 *    mentions|mobile|android|chrome - Chrome on Android only
 *    mentions|mobile|iphone|safari - Safari on Iphone
 * */
import * as detects from './detects';

const evite = (window.evite = window.evite || {});
export const features = (evite.features = evite.features || {});

if (evite.features.DEFINED) {
  throw new Error(
    'Unexpected copy of evite.features module exported, you might have used an unusual import instead of the "evite" alias.'
  );
}
export const DEFINED = true;

export function isEnabled(name, opt_defaultValueForMissingKey) {
  name = name.toUpperCase();
  const keyMissing = name in features === false;

  return Boolean(
    (arguments.length === 1 && keyMissing) ||
      (keyMissing && opt_defaultValueForMissingKey) ||
      get(name)
  );
}

export function get(name) {
  name = name.toUpperCase();

  try {
    return JSON.parse(features[name]);
  } catch (error) {
    return features[name];
  }
}

export function set(name, value) {
  name = name.toUpperCase();
  features[name] = value;
}

let bitPosition = 0;
function nextBitFlag() {
  const bit = 1 << bitPosition;
  bitPosition++;
  return bit;
}

const MOBILE_FLAG = nextBitFlag();
const DESKTOP_FLAG = nextBitFlag();

const IPAD_FLAG = nextBitFlag() | DESKTOP_FLAG;
const IPHONE_FLAG = nextBitFlag() | MOBILE_FLAG;
const ANDROID_FLAG = nextBitFlag() | MOBILE_FLAG;

const IE_FLAG = nextBitFlag();
const EDGE_FLAG = nextBitFlag();
const FIREFOX_FLAG = nextBitFlag();
const CHROME_FLAG = nextBitFlag();
const SAFARI_FLAG = nextBitFlag();

export function parseOptionsFromString(str) {
  const isAndroid = /\bAndroid\b/i.test(str);
  const isIPhone = /\biPhone\b/i.test(str);
  const isIPad = /\biPad\b/i.test(str);
  const isMobile = /\bMobile\b/i.test(str) || isIPhone || isAndroid;
  const isDesktop = /\bDesktop\b/i.test(str) || !isMobile;

  return {
    isIPhone,
    isAndroid,
    isIPad,
    isMobile,
    isDesktop,
    isIE: /\bIE\b/i.test(str),
    isEdge: /\bEdge\b/i.test(str),
    isChrome: /\bChrome\b/i.test(str),
    isFirefox: /\bFirefox\b/i.test(str),
    isSafari: /\bSafari\b/i.test(str),
  };
}

function parseFlagsFromOptions(options) {
  const mobile = (options.isMobile && MOBILE_FLAG) || 0;
  const desktop = (options.isDesktop && DESKTOP_FLAG) || 0;
  const viewport = mobile | desktop;

  const ipad = (options.isIPad && IPAD_FLAG) || 0;
  const iphone = (options.isIPhone && IPHONE_FLAG) || 0;
  const android = (options.isAndroid && ANDROID_FLAG) || 0;
  const device = ipad | iphone | android;

  const ie = (options.isIE && IE_FLAG) || 0;
  const edge = (options.isEdge && EDGE_FLAG) || 0;
  const firefox = (options.isFirefox && FIREFOX_FLAG) || 0;
  const chrome = (options.isChrome && CHROME_FLAG) || 0;
  const safari = (options.isSafari && SAFARI_FLAG) || 0;
  const browser = ie | edge | firefox | chrome | safari;

  return {
    viewport,
    mobile,
    desktop,
    device,
    ipad,
    iphone,
    android,
    browser,
    ie,
    edge,
    firefox,
    chrome,
    safari,
  };
}
/** Override the feature switch with most specific value based off of current device
 * viewport < device-vendor < browser
 */
// TODO - Add tests when test branch is merged in
export function getFeatureSwitchesForType(strOrOptions) {
  const options =
    typeof strOrOptions === 'string'
      ? parseOptionsFromString(strOrOptions)
      : typeof strOrOptions === 'object'
        ? strOrOptions
        : detects;

  const currentFlags = parseFlagsFromOptions(options);

  const matchedFeatureSwitches = {};
  const highestSpecificityByFeature = {};

  for (const key in features) {
    const value = features[key];
    if (typeof value === 'function') {
      continue;
    }

    if (key.indexOf('|') === -1) {
      if (key.toUpperCase() in matchedFeatureSwitches === false) {
        matchedFeatureSwitches[key.toUpperCase()] = value;
      }
      continue;
    }

    const featureName = key.slice(0, key.indexOf('|')).toUpperCase();
    if (featureName in features && !features[featureName]) {
      continue;
    }

    const featureFlags = parseFlagsFromOptions(parseOptionsFromString(key));

    if (!featureFlags.viewport && !featureFlags.browser && !featureFlags.device) {
      continue;
    }

    const viewportNotSpecified = !featureFlags.viewport && !currentFlags.viewport;
    const matchesViewport =
      (featureFlags.viewport & currentFlags.viewport) === featureFlags.viewport;
    if (viewportNotSpecified || matchesViewport) {
      const browserNotSpecified = !featureFlags.browser && !currentFlags.browser;
      const matchesBrowser = (featureFlags.browser & currentFlags.browser) === featureFlags.browser;
      if (browserNotSpecified || matchesBrowser) {
        const deviceNotSpecified = !featureFlags.device && !currentFlags.device;
        const matchesDevice = (featureFlags.device & currentFlags.device) === featureFlags.device;
        if (deviceNotSpecified || matchesDevice) {
          const specificity = featureFlags.browser | featureFlags.device | featureFlags.viewport;
          const highestSpecificity = (highestSpecificityByFeature[featureName] =
            highestSpecificityByFeature[featureName] || 0);
          if (specificity > highestSpecificity) {
            matchedFeatureSwitches[featureName] = value;
            highestSpecificityByFeature[featureName] = specificity;
          }
        }
      }
    }
  }

  return matchedFeatureSwitches;
}

export function getFeatureSwitches() {
  return getFeatureSwitchesForType(detects);
}

// Override the feature switch with most specific value based off of current device
Object.assign(features, getFeatureSwitches(detects));
Object.assign(features, exports);
