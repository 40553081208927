import regeneratorRuntime from 'regenerator-runtime';
import * as promises from './promises';

/**
 * @note imports happen at the end of the file, so async functions
 * in this file must take precautions caused from hoisting.
 * */
window.evite = window.evite || {};
const {evite} = window;
window.regeneratorRuntime = regeneratorRuntime;

if (evite.utils) {
  throw new Error(
    'Unexpected copy of evite.utils module exported, you might have used an unusual import instead of the "evite" alias.'
  );
}

const globalIdDefaultPrefix = Math.floor(Math.random() * 2147483647).toString(36);
let globalIdIndex = 0;

export function noop(e = null) {
  e && e.preventDefault && e.preventDefault();
}

export function isFunction(obj) {
  return Boolean(typeof obj === 'function' || (obj && obj.constructor && obj.call && obj.apply));
}

export function str(value) {
  if (typeof value === 'string') {
    return value;
  }
  if (value) {
    return value.toString();
  }
  return '';
}

export function int(value) {
  // eslint-disable-next-line no-bitwise
  return value | 0;
}

export function num(value) {
  return Number(value) || 0;
}

export function len(value) {
  return (
    value &&
    (Object.prototype.hasOwnProperty.call(value, 'length') ||
      (typeof value === 'object' && 'length' in value)) &&
    num(value.length)
  );
}

export function guid(opt_prefix = '') {
  const timeGuid = Date.now().toString(36);
  return `${
    opt_prefix ? `${opt_prefix}-` : ''
  }${globalIdDefaultPrefix}-${timeGuid}-${globalIdIndex++}`;
}

export function scope(fn) {
  fn();
}

export function convertCasing(str, {from, to}) {
  from = from.toLowerCase();
  to = to.toLowerCase();

  let seperator = '';
  if (from.includes('camel') && to.includes('kabob')) {
    seperator = '-';
  } else if (from.includes('camel') && to.includes('snake')) {
    seperator = '_';
  } else {
    throw new Error(`Unimplemented conversion from:${from} to:${to}`);
  }

  str = str
    .replace(/^[A-Z](?![A-Z])/, (firstCapitalLetter) => firstCapitalLetter.toLowerCase())
    .replace(/^[A-Z]+/, (firstInitials) => firstInitials.toLowerCase() + seperator)

    .replace(/[A-Z]+/g, `${seperator}$&`);

  if (to.includes('upper') && to.includes('camel')) {
    str = str.replace(/^[a-z]|(<=_)[a-z]/g, (char) => char.toUpperCase());
  } else if (to.includes('upper')) {
    str = str.toUpperCase();
  } else {
    str = str.toLowerCase();
  }

  return str;
}

export function param(obj, sorted = true) {
  const keyList = Object.keys(obj);
  if (sorted) keyList.sort();
  return keyList.reduce((accumulator, key) => {
    if (accumulator) accumulator += `&`;
    accumulator += `${key}=${(obj[key] || '').toString().replace(/\s/g, '+')}`;
    return accumulator;
  }, '');
}

function liveBindException(arg, value, valid) {
  this.message = 'liveBindException: ';
  this.name = 'liveBindException';
  if (arg) {
    this.message += `liveBind argument "${arg}" missing or invalid`;
  }
  if (value) {
    this.message += ` –– "${value}" supplied`;
  }
  if (valid) {
    this.message += `, should be "${valid}"`;
  }
}

/**
 * Execute a function and handle exceptions. This function should never throw an exception.
 *
 * @param {!Element} rootNode
 * @param {string} eventType: the event being bound
 * @param {string} childQuerySelector: '#selectorId, .selectorClass'
 * @param {!Function} callback: function to call when the event is triggered -
 *                  if callback's "thisArg" is not bound, passes event target as first param.
 */
export function liveBind(rootNode, eventType, childQuerySelector, callback) {
  if (!rootNode) {
    // if node is not on the page, do not go any further.
    return;
  }
  try {
    if (!evite.dom.isDomNode(rootNode)) {
      throw new liveBindException('rootNode', rootNode, 'Node');
    } else if (!eventType || typeof eventType !== 'string') {
      throw new liveBindException('eventType', eventType, 'string');
    } else if (!childQuerySelector || typeof childQuerySelector !== 'string') {
      throw new liveBindException('childQuerySelector', childQuerySelector, 'string');
    } else if (!callback || typeof callback !== 'function') {
      throw new liveBindException('callback', callback, 'function');
    }
    rootNode.addEventListener(eventType, (e) => {
      if (e.target && e.target.matches) {
        if (e.target.matches(childQuerySelector)) {
          callback.call(e.target, e, ...arguments);
        }
      } else {
        // TODO - remove after https://sentry.evite.com/sentry/prod/issues/14959/ is resolved.
        evite.logError(`Property 'matches' of object of object ${e.target} is not a function.`);
      }
    });
  } catch (exception) {
    if (process.env.NODE_ENV !== 'production') {
      throw exception;
    } else {
      evite.warn(`${exception.name}: ${exception.message}`);
    }
  }
}

export const fetch = (function _fetchFn() {
  return async function _fetch(url, optConfig = {}) {
    const fetchFn = await evite.when('fetch');
    const config = {...optConfig};

    // the user's headers need to be included all the time or the backend middleware will regenerate
    // new session, feature, etc. cookies for the user, generally messing things up.
    // also, some may depend on headers for the correct experiment.
    if (!config.credentials) {
      config.credentials = 'include';
    }
    config.headers = config.headers || {};
    config.headers['X-EVITE-VERSION'] = window.evite_version || 'untagged';

    const response = await fetchFn(url, config);
    const {headers} = response;
    const contentType =
      headers && ((headers.get && headers.get('Content-Type')) || headers['Content-Type']);
    const isJsonContentType = contentType && contentType.includes('json');

    if (isJsonContentType) {
      // make sure to clone before the callback so we don't accidentally wind up re-reading the body
      const clonedResponse = response.clone();
      evite
        .when('dfp')
        .then(() => clonedResponse.json())
        .then((json) => {
          if (window.dataLayer && json && typeof json === 'object' && json.data_layer) {
            window.dataLayer.push(json.data_layer);
          }
        })
        .catch((e) => {
          evite.error('Failed to update dataLayer');
          Raven.captureException(e);
        });
    }

    return response;
  };
})();

export const fetchJson = (function _fetchJsonFn() {
  return async function _fetchJson(url, optConfig = {}) {
    const config = {...optConfig};
    config.headers = config.headers || {};
    config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';
    config.headers['X-EVITE-VERSION'] = window.eviteVersion || 'untagged';
    const response = await fetch(url, config);
    if (!response.ok) {
      evite.reject(url, response.statusText);
      return Promise.reject(response.statusText);
    }
    const json = await response.json();
    return json;
  };
})();

function requestIdleCallbackFallBack(callback) {
  return setTimeout(() => {
    const start = Date.now();
    callback({
      didTimeout: false,
      timeRemaining() {
        return Math.max(0, 50 - (Date.now() - start));
      },
    });
  }, 1);
}

export function requestIdleCallback(callback) {
  if (evite.global.requestIdleCallback) {
    return evite.global.requestIdleCallback(callback);
  }

  return requestIdleCallbackFallBack(callback);
}

export function sleep(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export function map(arrOrNum, fnOrValue) {
  const arr = Array.isArray(arrOrNum) ? arrOrNum : new Array(arrOrNum);
  const {length} = arr;

  const values = [];
  const isFn = evite.isFunction(fnOrValue);
  for (let i = 0; i < length; ++i) {
    values.push(isFn ? fnOrValue(arr[i], i, arr) : fnOrValue);
  }
  return values;
}

// TODO - move out of this file
export function lazyLoadImage({el, loadWhen, src, priority}) {
  // TODO - remove after correcting cms image uploader protocol for signed-out-homepage
  const isHTTP = /^http:\/\//i.test(src);
  if (isHTTP) {
    evite.warn(`Expected "https" in src, got: ${src}`);
    src = src.replace('http://', 'https://');
  }

  return new Promise((resolve, reject) => {
    evite.hide(el);

    const parent = el.parentNode || document.createDocumentFragment();
    const statusElementList = Array.from(
      parent.querySelectorAll('.status-animation, .status-animation__icon')
    );

    statusElementList.forEach((statusElement) => {
      evite.removeClass(statusElement, 'status-animation--complete');
      evite.addClass(statusElement, 'status-animation--loading');
      evite.show(statusElement);
    });

    const readyToBeginLoading = loadWhen ? evite.when(loadWhen) : Promise.resolve();
    readyToBeginLoading.then(() => {
      preloadImage(src, priority || 0)
        .then(() => {
          statusElementList.forEach((statusElement) => {
            evite.removeClass(statusElement, 'status-animation--loading');
            evite.addClass(statusElement, 'status-animation--complete');
            evite.hide(statusElement);
          });

          el.src = src;
          evite.show(el);

          resolve(el);
        })
        .catch((message) => {
          if (statusElementList.length) {
            statusElementList.forEach((statusElement) => {
              evite.removeClass(statusElement, 'status-animation--loading');
              evite.addClass(statusElement, 'status-animation--error');
              evite.show(statusElement);
            });

            const originalDescription = el.alt || el.title;
            statusElementList[0].title = `An error occurred downloading the image.${
              originalDescription ? `\n${originalDescription}` : ''
            }`;
          }

          reject(message);
        });
    });
  });
}

const {hasOwnProperty} = Object.prototype;

export function hasOwn(obj, key) {
  return hasOwnProperty.call(obj, key);
}

const OBJECT_STRING = '[object Object]';

export function isPlainObject(obj) {
  return toString.call(obj) === OBJECT_STRING;
}

const imageQueue = [];

function preloadImage(src, priority) {
  let pending = [];
  for (let i = 0; i <= priority; ++i) {
    const queue = (imageQueue[i] = imageQueue[i] || []);
    if (i !== priority) {
      pending = pending.concat(queue);
    }
  }
  imageQueue[priority].push(evite.when(src));

  return Promise.all(pending).then(load).catch(load);

  function load() {
    if (src) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => {
          evite.resolve(src, image).then(resolve);
        });
        image.addEventListener('error', (event) => {
          reject(`Image failed to load: src="${src}"'`);
        });
        image.src = src;
      });
    }
  }
}

export function clamp(params = {}) {
  const {value, min, max} = params;
  return Math.min(Math.max(value, min), max);
}

export function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.substr(1);
}

export function toJSON(value) {
  if ((!value && value === null) || value === undefined) {
    return value;
  }

  if (typeof value === 'function' || value instanceof Function) {
    return null;
  }

  if (typeof value === 'object' && 'toJSON' in value) {
    value = value.toJSON();
  }

  if (Array.isArray(value)) {
    return value.map(toJSON);
  }

  if (typeof value === 'object') {
    const attributes = {};
    for (const key in value) {
      attributes[key] = toJSON(value[key]);
    }
    return attributes;
  }

  try {
    return JSON.parse(value);
  } catch (error) {}

  return value;
}

export function debounce(func, wait, immediate) {
  let timeout;
  let result;

  function delay(func, wait, ...args) {
    return setTimeout(() => func.apply(null, args), wait);
  }

  function later(context, args) {
    timeout = null;
    if (args) {
      result = func.apply(context, args);
    }
  }

  function debounced(...args) {
    // if the first argument looks like it's a react synthetic event, we
    // need to persist it. for more information, see:
    // https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js
    if (args && args.length > 0 && args[0] && args[0].persist && args[0].preventDefault) {
      args[0].persist();
    }

    if (timeout) {
      clearTimeout(timeout);
    }
    if (immediate) {
      const callNow = !timeout;
      timeout = setTimeout(later, wait);
      if (callNow) {
        result = func.apply(this, args);
      }
    } else {
      timeout = delay(later, wait, this, args);
    }
    return result;
  }

  debounced.cancel = function () {
    clearTimeout(timeout);
    timeout = null;
  };
  return debounced;
}

export function get(root, name, opt_defaultValue = null) {
  name = name
    .replace(/\s*[[\]]\s*/g, '.')
    .replace(/^\.+/g, '')
    .replace(/\.{2,}/g, '.');

  const parts = name.split('.');
  let cur = root;
  for (let i = 0; i < parts.length; i++) {
    const isLast = i === parts.length - 1;

    cur = cur && cur[parts[i]];
    if (isLast && !cur && ['number', 'string', 'boolean'].includes(typeof cur)) {
      return cur;
    }

    if (isLast && !cur) {
      return opt_defaultValue;
    }
  }

  return cur;
}

export function set(root, name, value, options = {strict: false}) {
  name = name
    .replace(/\s*[[\]]\s*/g, '.')
    .replace(/^\.+/g, '')
    .replace(/\.{2,}/g, '.');

  const parts = name.split('.');
  let cur = root;
  for (let i = 0; i < parts.length - 1; i++) {
    const last = cur;
    cur = cur && cur[parts[i]];
    if (!cur) {
      if (options && options.strict) {
        evite.error('Set Path not found.  --path=%s --root=%O', name, root);
        throw new Error(`Set path not "${name}"`);
        return;
      }
      cur = last[parts[i]] = {};
    }
  }

  const lastKey = parts.length ? parts.pop() : '';
  cur[lastKey] = value;

  return cur[lastKey];
}

export const stores = {};

export function injectStores(fnOrObj) {
  const args = Array.from(arguments);
  fnOrObj = args.pop();

  let keys = args;
  if (Array.isArray(keys[0])) {
    keys = keys[0];
  }

  if (typeof fnOrObj === 'function') {
    const fn = fnOrObj;
    return function () {
      const clonedStores = {...stores};
      return fn.apply(this, [clonedStores].concat(Array.from(arguments)));
    };
  }

  if (!keys.length) {
    return Object.assign(fnOrObj, stores);
  }

  keys.forEach((key) => {
    Object.defineProperty(fnOrObj, key, {
      get() {
        return stores[key];
      },
    });
  });

  return fnOrObj;
}

export function provideStores(other) {
  Object.assign(stores, other);
}

promises.when('evite').then((ev) => {
  function handleReadyStateChange() {
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
      ev.resolve('document.ready', document);
    }

    if (document.readyState === 'complete') {
      ev.resolve('window.load', window);
    }
  }

  document.addEventListener('readystatechange', handleReadyStateChange);
  document.addEventListener('DOMContentLoaded', handleReadyStateChange);
  document.addEventListener('load', handleReadyStateChange);

  handleReadyStateChange();

  function handleScroll() {
    if (window.pageYOffset) {
      document.removeEventListener('scroll', handleScroll);
      ev.resolve('document.scroll');
    }
  }

  document.addEventListener('scroll', handleScroll);
  handleScroll();
});

promises.when('document.ready').then(() => {
  if (window.requestAnimationFrame) {
    window.requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        evite.resolve('page.render');
      });
    });
  }
});

window.addEventListener('onload', () => {
  evite.resolve('window.load');
});

export function getHostName() {
  const originalHostName = window.location.hostname;
  if (originalHostName.includes('evitelabs')) {
    return originalHostName;
  }
  if (originalHostName.includes('appspot.com')) {
    return originalHostName;
  }
  if (originalHostName.includes('evite')) {
    return originalHostName.split('.').slice(-2).join('.');
  }
  return originalHostName;
}
