const evite = (window.evite = window.evite || {});
const promiseMap = new Map();

if (evite.promises) {
  throw new Error(
    'Unexpected copy of evite.promises module exported, you might have used an unusual import instead of the "evite" alias.'
  );
}

/**
 * @param namesOrPromises {...string|!Promise|!Array.<string|!Promise>}
 * @returns Promise
 * */
export function when(...namesOrPromises) {
  const promises = [];
  for (const nameOrPromise of namesOrPromises) {
    let promise;
    if (nameOrPromise instanceof Promise) {
      promise = nameOrPromise;
    } else if (typeof nameOrPromise === 'string' || Array.isArray(nameOrPromise)) {
      promise = ensurePromiseMapping(nameOrPromise).promise;
    } else {
      evite.assert(
        false,
        'Expected arguments to be either a promise, a string, or a list got: %O.',
        nameOrPromise
      );
      return null;
    }

    promises.push(promise);
  }

  if (promises.length === 1) {
    return promises[0];
  }

  return Promise.all(promises);
}

function ensurePromiseMapping(name) {
  if (!promiseMap.get(name)) {
    const mapping = createPromiseMapping();
    promiseMap.set(name, mapping);

    if (process.env.LOGGING_ENABLED) {
      mapping.promise.then((value) => {
        evite.debug('evite.resolve:', name);
      });
    }

    if (Array.isArray(name)) {
      when
        .apply(null, name)
        .then(resolvePromiseByName.bind(null, name))
        .catch(rejectPromiseByName.bind(null, name));
    }
  }

  return promiseMap.get(name);
}

function createPromiseMapping() {
  const mapping = {};
  mapping.promise = new Promise((resolve, reject) => {
    mapping.resolve = resolve;
    mapping.reject = function (msgOrError) {
      msgOrError = msgOrError || 'Promise rejected.';
      const error = typeof msgOrError === 'string' ? new Error(msgOrError) : msgOrError;
      reject(error);
    };
  });

  return mapping;
}
/**
 * @param namesOrPromise {string}
 * @param opt_value {*=} The value to send to the resolved promise
 * @returns Promise
 * */
export function resolvePromiseByName(name, opt_value) {
  const mapping = ensurePromiseMapping(name);
  mapping.resolve(opt_value);
  return mapping.promise;
}

export function resolve(dataOrName, opt_value) {
  if (typeof dataOrName === 'string') {
    return resolvePromiseByName(dataOrName, opt_value);
  }
  const nameList = [];
  for (const name in dataOrName) {
    nameList.push(name);
    resolvePromiseByName(dataOrName, dataOrName[name]);
  }

  return when(nameList);
}

/**
 * @param namesOrPromise {string}
 * @param opt_reason {*=} The error or message to send to the resolved promise
 * @returns Promise
 * */
export function rejectPromiseByName(name, opt_reason) {
  const mapping = ensurePromiseMapping(name);
  mapping.reject(opt_reason);
  return mapping.promise;
}

export function reject(dataOrName, opt_value) {
  if (typeof dataOrName === 'string') {
    return rejectPromiseByName(dataOrName, opt_value);
  }
  const nameList = [];
  for (const name in dataOrName) {
    nameList.push(name);
    rejectPromiseByName(dataOrName, dataOrName[name]);
  }

  return when(nameList);
}

const commands = evite.command;
when('evite').then((evite) => {
  if (commands) {
    while (commands.length) {
      const commandData = commands.shift();
      const {name, params, promiseCommand} = commandData;

      const promise = evite[name].apply(evite, params);

      for (const promiseCommandData of promiseCommand) {
        const {name, params} = promiseCommandData;
        promise[name].apply(promise, params);
      }
    }
  }
});

export function jwPlayerWrapper(props) {
  return evite.when('jwplayer').then(
    (jwplayer) =>
      new Promise((resolve, reject) => {
        const videoplayer = new VideoPlayer(props);
        resolve(videoplayer.when());
      })
  );
}

class VideoPlayer {
  userPaused = false;

  constructor({id, when, ...setupConfig}) {
    when = when || Promise.resolve();
    this.promise = evite.when('jwplayer', when).then(([jwplayer]) => {
      this.player = jwplayer(id)
        .setup(setupConfig)
        .on('pause', this.handlePause)
        .on('adPause', this.handlePause)
        .on('play', this.handlePlay)
        .on('adPlay', this.handlePlay)
        .on('viewable', this.handleViewable);
      return this.player;
    });
  }

  when = () => this.promise;

  handlePause = (payload) => {
    if (payload && payload.pauseReason === 'interaction') {
      this.userPaused = true;
    }
  };

  handlePlay = (payload) => {
    if (payload && payload.playReason === 'interaction') {
      this.userPaused = false;
    }
  };

  handleViewable = (t) => {
    if (!this.userPaused) {
      if (t.viewable === 0) {
        this.player.pause(true);
      } else if (t.viewable == 1) {
        this.player.play(true);
      }
    }
  };
}

Object.assign(evite, exports);
