const evite = window.evite || {};

if (evite.dom) {
  throw new Error(
    'Unexpected copy of evite.dom module exported, you might have used an unusual import instead of the "evite" alias.'
  );
}

if (Element && !Element.prototype.matches) {
  const proto = Element.prototype;

  proto.matches =
    proto.matchesSelector ||
    proto.mozMatchesSelector ||
    proto.msMatchesSelector ||
    proto.oMatchesSelector ||
    proto.webkitMatchesSelector;
}

export function appendChild(subject, child) {
  if (subject && subject.appendChild) {
    subject.appendChild(child);
  } else if (subject && subject.append) {
    subject.append(child);
  }
}

export function removeChild(subject, child) {
  if (subject && subject.removeChild) {
    subject.removeChild(child);
  } else if (subject && subject.remove) {
    subject.remove(child);
  }
}

export function removeChildren(subject) {
  if (subject) {
    while (subject.lastChild) {
      removeChild(subject, subject.lastChild);
    }
  }
}

export function createElement(str) {
  if (str) {
    const div = document.createElement('div');
    div.innerHTML = str.trim();
    return div.firstChild;
  }

  return null;
}

export function css(el, styles) {
  /* eslint-disable no-param-reassign */
  if (!el) {
    return;
  }

  if (!styles) {
    el.style.cssText = '';
    return;
  }

  // eslint-disable-next-line guard-for-in
  for (const prop in styles) {
    const value = styles[prop];
    if (typeof value === 'number') {
      el.style[prop] = `${value}px`;
    } else {
      el.style[prop] = value;
    }
  }
  /* eslint-enable no-param-reassign */
}

export function hasClass(el, className) {
  const $ = window.jQuery || null;
  if ($) {
    return $(el).hasClass(className);
  }

  if (el && el.classList) {
    return el.classList.contains(className);
  }
  if (el && el.className) {
    const classes = el.className.split(/\s+/);
    return classes.includes(className);
  }

  return false;
}

export function addClass(el, className) {
  const $ = window.jQuery || null;
  if ($) {
    $(el).addClass(className);
    return;
  }

  if (el && el.classList && !el.classList.contains(className)) {
    el.classList.add(className);
  } else if (el && el.className) {
    const classes = el.className.split(/\s+/);
    if (!classes.includes(className)) {
      classes.push(className);
      // eslint-disable-next-line no-param-reassign
      el.className = classes.join(' ');
    }
  }
}

export function removeClass(el, className) {
  const $ = window.jQuery || null;
  if ($) {
    $(el).removeClass(className);
    return;
  }

  if (el && el.classList && el.classList.contains(className)) {
    el.classList.remove(className);
  } else if (el && el.className) {
    const classes = el.className.split(/\s+/);
    if (classes.includes(className)) {
      classes.splice(classes.indexOf(className), 1);
      // eslint-disable-next-line no-param-reassign
      el.className = classes.join(' ');
    }
  }
}

export function toggleClass(el, className, add) {
  if (add || (arguments.length === 2 && !hasClass(el, className))) {
    addClass(el, className);
  } else {
    removeClass(el, className);
  }
}

export function show(subject) {
  removeClass(subject, 'hidden');
}

export function hide(subject) {
  addClass(subject, 'hidden');
}

/**
 * Scroll to an element on the page
 *
 * modified from https://stackoverflow.com/questions/17722497/scroll-smoothly-to-specific-element-on-page
 * https://jsfiddle.net/s61x7c4e/
 *
 * @param element dom element
 * @param duration
 * @param marginTop additional header room for fixed elements, or if you want to scroll above `element`
 */
export function scrollTo(element, duration, marginTop = 0) {
  const startingY = window.pageYOffset;
  const elementY = window.pageYOffset + element.getBoundingClientRect().top - marginTop;
  // If element is close to page's bottom then window will scroll only to some position above the element.
  const tooClose = document.body.scrollHeight - elementY < window.innerHeight;
  const targetY = tooClose ? document.body.scrollHeight - window.innerHeight : elementY;
  const diff = targetY - startingY;
  // Easing function: easeInOutCubic
  // From: https://gist.github.com/gre/1650294
  function easing(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  }
  let start;

  if (!diff) return;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    const time = timestamp - start;
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1);
    // Apply the easing.
    // It can cause bad-looking slow frames in browser performance tool, so be careful.
    percent = easing(percent);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) window.requestAnimationFrame(step);
  });
}

const idleLoadingQueue = [];
let loadingInProgress;

function handleQueueLoadComplete(url, value) {
  loadingInProgress = false;
  if (url) {
    evite.resolve(url, value);
  }
  // eslint-disable-next-line no-use-before-define
  evite.requestIdleCallback(loadNextInQueue);
}

export function loadImage(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', resolve.bind(null, image));
    image.addEventListener('error', reject);
    image.src = src;
  });
}

function loadNextInQueue() {
  if (!loadingInProgress && idleLoadingQueue.length) {
    loadingInProgress = true;

    const url = idleLoadingQueue.pop();
    const isImage = /\.(jpeg|jpg|gif|png)\b/i.test(url);
    if (isImage) {
      const completeHandler = handleQueueLoadComplete.bind(null, url);
      loadImage(url).then(completeHandler).catch(completeHandler);
      return;
    }

    const isCss = /\.(css)\b/i.test(url);
    if (isCss) {
      const link = evite.dom.createElement('<link rel="stylesheet" media="none"/>');
      const completeHandler = handleQueueLoadComplete.bind(null, url, link);
      link.addEventListener('load', completeHandler);
      link.addEventListener('error', completeHandler);
      link.setAttribute('href', url);
      document.body.appendChild(link);
      return;
    }

    handleQueueLoadComplete();
  }
}

export function addToLoadingQueue({url, priority, when}) {
  evite.when(when || 'window.load').then(() => {
    if (url) {
      idleLoadingQueue.splice(priority || 0, 0, url);
      if (!loadingInProgress) {
        evite.requestIdleCallback(loadNextInQueue);
      }
    }
  });
}

export function isDomNode(o) {
  if (!o) {
    return false;
  }
  return (
    (window.HTMLElement && o instanceof HTMLElement) ||
    (o.nodeType === 1 && typeof o.nodeName === 'string')
  );
}

export function styleModifiers(styleName, modifiers) {
  /* eslint-disable no-param-reassign */
  styleName = styleName.replace(/^[A-Z]+/, (str) => str.toLowerCase());

  evite.assertString(styleName);
  if (!modifiers) {
    return '';
  }
  let literalClassName = '';

  const isArrayLike =
    modifiers &&
    typeof modifiers === 'object' &&
    (Array.isArray(modifiers) ||
      ('length' in modifiers &&
        typeof modifiers.map === 'function' &&
        typeof modifiers.join === 'function'));

  if (isArrayLike) {
    modifiers = modifiers.map((mods) => styleModifiers(styleName, mods));
  }

  if (typeof modifiers === 'string') {
    modifiers = modifiers.split(/\s+/);
  }

  if (typeof modifiers === 'object' && !Array.isArray(modifiers)) {
    const tempModifiers = [];
    for (const name in modifiers) {
      if (name === 'className' && modifiers[name]) {
        literalClassName = modifiers[name];
      } else if (modifiers[name]) {
        tempModifiers.push(name);
      }
    }
    modifiers = tempModifiers;
  }

  modifiers.sort();
  const classNames = [];
  for (let i = 0; i < modifiers.length; ++i) {
    const name = evite.convertCasing(modifiers[i], {from: 'camel', to: 'kabob'});

    if (name !== styleName) {
      classNames.push(`${styleName}--${name}`);
    }
  }

  if (literalClassName) {
    classNames.unshift(literalClassName);
  }

  if (classNames.length <= 1) {
    return classNames.join('');
  }
  return `\n${classNames.join('\n')}\n`;
  /* eslint-enable no-param-reassign */
}

let $body;
let $window;
let viewWidth;

export function isBreakPoint(name) {
  const jq = window.jQuery;
  $body = $body || jq('body');
  $window = $window || jq(window);

  const hasOverflow = /\boverflow\b/i.test($body.attr('style') || '');
  const originalOverflow = hasOverflow ? $body.css('overflow') : '';
  $body.css('overflow', 'hidden');
  viewWidth = $window.width();

  $body.css('overflow', originalOverflow);

  const tolerance = 0.001;
  const smallBreakpointLimit = 500;
  const mediumBreakpointLimit = 768;
  const desktopBreakpointLimit = 1279;

  switch (name) {
    case 'small-and-down':
      return viewWidth <= smallBreakpointLimit - tolerance;
    case 'medium-and-down':
      return viewWidth <= mediumBreakpointLimit - tolerance;
    case 'medium-and-up':
      return viewWidth > smallBreakpointLimit;
    case 'large-and-up':
      return viewWidth > mediumBreakpointLimit;
    case 'desktop':
      return viewWidth < desktopBreakpointLimit;
    default:
      if (console && console.error) {
        console.error(`Unknown breakpoint "${name}"`);
      }
      return false;
  }
}
